@import "responsive";


@include respond-to(desktop) {
	// main page
	.s-header-nav {
		display: none; }
	.s-header-lang {
		display: none; }
	.s-header-contacts {
		display: none; }
	.s-header-work {
		display: none; }
	.b-logo {
		padding-top: 0; }
	.s-header {
		padding: 33px 0; }
	.slick-arrow.slick-next {
		right: 0; }
	.slick-arrow.slick-prev {
		left: 0; }
	.b-slider-item {
		padding: 136px 80px 0; }
	.s-about-img {
		margin-top: -50px; }
	.s-partners-slider {
		padding: 0 80px; }
	.s-partners .slick-arrow.slick-prev {
		left: 0; }
	.s-partners .slick-arrow.slick-next {
		right: 0; }
	.s-news-slider {
		padding: 0 80px; }
	.s-news .slick-arrow.slick-prev {
		left: 0; }
	.s-news .slick-arrow.slick-next {
		right: 0; }
	.s-events-slider {
		padding: 0 80px; }
	.owl-nav button.owl-prev {
		left: 0; }
	.owl-nav button.owl-next {
		right: 0; }
	.s-header-toggle {
		display: block; }
	// main page
	.s-header.inner {
		padding: 18px 0; }
	.s-contant.top {
		padding-top: 80px; }
	// page partners
	.p-partners-list li a {
		padding: 0 20px; }
	.p-partners-map-block {
		right: 50px; }
 }	// page partners
@include respond-to(tablet-large) {
	// main page
	.b-slider-title {
		font-size: 36px;
		line-height: 51px; }
	.s-about-title {
		font-size: 33px;
		line-height: 54px; }
	.s-about-info-title {
		font-size: 11px; }
	.s-about-info-descr {
		font-size: 12px; }
	.s-about-info-block {
		padding: 0 5px; }
	.s-about-info {
		margin: 0 -5px; }
	.s-about-info-item.item1::after {
		height: 295px; }
	.s-about-info-item.item2::after {
		height: 105px; }
	.s-about-info-item.item3::after {
		height: 345px; }
	.s-about-info-item.item4::after {
		height: 148px; }
	.s-about-info-item.item5::after {
		height: 293px; }
	.s-about-info-item.item6::after {
		height: 176px; }
	.s-news-item {
		padding: 32px; }
	.s-news-slider {
		padding: 0 48px; }
	.s-footer-contacts-block {
		width: 50%;
		margin-bottom: 25px; }
	.s-footer-contacts-line {
		margin-bottom: -25px; }
	// main page
	// page news
	.p-news .s-news-item-block {
		width: 100%; }
	.p-news-block {
		width: 50%; }
	// page news
	// page partners
	.p-partners-block {
		width: 50%; }
	.p-partners-line {
		align-items: stretch; }
	.p-partners .s-partners-item-inner {
		height: 100%; }
	.p-partners-nav {
		padding-top: 45px;
		position: relative; }
	.p-partners-dropdown {
		position: absolute;
		height: 50px;
		top: 82px;
		display: block;
		width: 100%;
		z-index: 20; }
	.b-select {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;
		flex-wrap: wrap;
		background: linear-gradient(156.56deg, #0192D5 0.8%, #37B451 100%);
		padding: 1px;
		height: 50px;
		cursor: pointer;
		&-content {
			display: block;
			width: 100%;
			height: 100%;
			background-color: #fff;
			font-family: Montserrat;
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.06em;
			text-transform: uppercase;
			color: #1EA58F;
			line-height: 48px;
			padding: 0 45px 0 15px;
			position: relative;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			&:after {
				content: '';
				position: absolute;
				right: 14px;
				top: 50%;
				transform: translateY(-50%);
				background-image: url("../img/arrow-down.svg");
				width: 17px;
				height: 12px;
				display: block;
				background-repeat: no-repeat; } } }
	.p-partners-contant {
		padding-top: 97px; }
	.p-partners-list {
		background-color: #fff;
		background: linear-gradient(156.56deg, #0192D5 0.8%, #37B451 100%);
		padding: 0px 1px 1px 1px;
		display: block;
		li {
			background-color: #fff;
			margin-right: 0;
			border-bottom: 1px solid #20A68B;
			&.selected {
				display: none; }
			a {
				padding: 0 15px;
				line-height: 54px;
				font-weight: 600;
				font-size: 13px; } } }

	.p-partners-list li a:after {
		display: none; }
	.ae-hide {
		display: none; }
	//page partners
 }	//page contant

@include respond-to(tablet) {
	// main page
	.wrap {
		padding: 0 10px; }
	.s-header {
		padding: 20px 0; }
	.s-header .wrap {
		padding: 0 10px; }
	.b-slider-item {
		padding: 116px 0 0;
		min-height: 640px; }
	.b-slider-title {
		font-size: 32px;
		line-height: 40px; }
	.b-slider-descr {
		font-size: 14px;
		line-height: 24px;
		padding-top: 26px; }
	.b-slider-button {
		padding-top: 28px; }
	.b-button {
		height: 48px;
		line-height: 48px;
		font-size: 11px;
		padding: 0 15px;
		max-width: 259px;
		width: 100%; }
	.b-slider .slick-dots li button {
		width: 8px;
		height: 8px; }
	.b-slider .slick-dots {
		bottom: 16px; }
	.b-slider .slick-dots li {
		margin: 0 6px; }
	.s-about {
		padding: 50px 0 0; }
	.s-about-title {
		font-size: 26px;
		line-height: 37px; }
	.s-about-subtitle {
		padding-top: 35px; }
	.s-about-descr {
		font-size: 14px;
		line-height: 24px;
		padding-top: 18px; }
	.mwidth50 {
		width: 50%;
		padding: 0 10px; }
	.s-about-line {
		margin: 0 -10px;
		padding-top: 50px; }
	.s-about-number {
		font-size: 36px;
		line-height: 45px; }
	.s-about-descr1 {
		font-size: 12px;
		line-height: 18px;
		padding-top: 0; }
	.s-about-block {
		margin-bottom: 24px; }
	.s-about-info-block {
		width: 100%; }
	.s-about-info-item {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: stretch;
		flex-wrap: wrap; }
	.s-about-info-item.item1 {
		max-width: 100%;
		padding-top: 0;
		&:after {
			display: none; } }
	.s-about-info-item.item2 {
		max-width: 100%;
		padding-top: 0;
		&:after {
			display: none; } }
	.s-about-info-item.item3 {
		max-width: 100%;
		padding-top: 0;
		&:after {
			display: none; } }
	.s-about-info-item.item4 {
		max-width: 100%;
		padding-top: 0;
		&:after {
			display: none; } }
	.s-about-info-item.item5 {
		max-width: 100%;
		padding-top: 0;
		&:after {
			display: none; } }
	.s-about-info-item.item6 {
		max-width: 100%;
		padding-top: 0;
		&:after {
			display: none; } }
	.s-about-info-img {
		width: 58px; }
	.s-about-info-text {
		width: calc(100% - 58px);
		padding-left: 10px;
		text-align: left; }
	.s-about-info-title {
		font-size: 14px;
		line-height: 20px;
		text-align: left;
		br {
			display: none; } }
	.s-about-info-descr {
		font-size: 14px;
		line-height: 19px;
		text-align: left;
		padding-top: 9px; }
	.s-about-bottom {
		padding-top: 28px;
		overflow: hidden; }
	.s-about-info-img.img1 img, .s-about-info-img.img1 svg {
		width: 43.32px;
		height: 58.02px;
		margin-top: 5px; }
	.s-about-info-img.img2 img, .s-about-info-img.img2 svg {
		width: 55px;
		height: 55px;
		margin-top: 10px; }
	.s-about-info-img.img3 img, .s-about-info-img.img3 svg {
		width: 43px;
		height: 43px;
		margin-top: 9px; }
	.s-about-info-img.img4 img, .s-about-info-img.img4 svg {
		width: 52px;
		height: 52px;
		margin-top: 10px; }
	.s-about-info-img.img5 img, .s-about-info-img.img5 svg {
		width: 49px;
		height: 48px;
		margin-top: 16px; }
	.s-about-info-img.img6 img, .s-about-info-img.img6 svg {
		width: 57px;
		height: 42px;
		margin-top: 10px; }
	.s-about-info-block {
		margin-bottom: 21px;
		&:last-child {
			margin-bottom: 0; } }
	.s-about-button {
		position: relative;
		bottom: auto;
		padding-top: 41px; }
	.s-partners {
		padding: 50px 0 60px; }
	.s-partners .s-about-descr {
		padding-top: 22px; }
	.s-partners-slider {
		padding: 0;
		margin-top: 36px; }
	.s-partners-item-inner {
		width: 260px;
		height: 346px; }
	.s-partners-place {
		font-size: 10px;
		line-height: 12px; }
	.s-partners-title {
		padding-top: 18px; }
	.s-partners-location {
		font-size: 12px;
		line-height: 18px;
		padding-left: 20px; }
	.s-partners-location::before {
		width: 10px;
		height: 13px; }
	.s-partners-descr {
		font-size: 12px;
		line-height: 24px;
		padding-top: 17px; }
	.s-partners-count {
		font-size: 12px;
		line-height: 15px; }
	.b-button1 {
		height: 48px;
		line-height: 44px;
		font-size: 11px;
		max-width: 213px;
		width: 100%; }
	.s-news {
		padding: 55px 0 60px; }
	.s-news-slider {
		margin-top: -8px;
		padding: 0; }
	.s-news-item {
		padding: 32px 7px; }
	.s-news-item-block {
		width: 260px;
		padding: 29px 22px 20px;
		height: 328px; }
	.s-news-date {
		font-size: 11px;
		line-height: 13px; }
	.s-news-title {
		font-size: 16px;
		line-height: 24px;
		padding-top: 17px;
		margin-right: -10px; }
	.s-news-descr {
		font-size: 12px;
		line-height: 20px;
		padding-top: 12px; }
	.s-news-button .b-button {
		padding: 0 15px;
		max-width: 217px; }
	.s-events .s-about-descr {
		padding-top: 28px; }
	.s-events-slider {
		padding: 0;
		margin: 0;
		margin-top: 43px; }
	.s-events-item {
		padding: 0; }
	.s-events-item-block.item1 {
		width: 100%; }
	.s-events-item-block.item2 {
		width: 100%; }
	.s-events-img {
		height: 137px; }
	.s-events-date {
		font-size: 11px;
		line-height: 13px; }
	.s-events-title {
		font-size: 14px;
		line-height: 22px;
		padding-top: 13px; }
	.s-events-text {
		padding: 19px 0; }
	.s-events-item {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0; } }
	.s-events-button {
		padding-top: 24px; }
	.s-events-button .b-button {
		padding: 0 15px;
		max-width: 217px; }
	.s-events {
		padding-bottom: 80px; }
	.s-footer-title {
		font-size: 20px;
		line-height: 37px; }
	.s-footer-top {
		padding: 36px 0 35px; }
	.s-footer-button {
		padding-top: 28px; }
	.b-button.style1 {
		line-height: 42px;
		max-width: 274px; }
	.s-footer-contacts {
		padding-top: 35px; }
	.s-footer-contacts-block {
		padding: 0 15px; }
	.s-footer-contacts-block {
		margin-bottom: 27px;
		&:last-child {
			margin-bottom: 0; } }
	.s-footer-link {
		font-size: 18px;
		line-height: 22px; }
	.s-footer-contacts-line {
		margin-bottom: 0; }
	.s-footer-top {
		padding-bottom: 47px; }
	.s-footer-bottom .wrap {
		padding: 0 10px; }
	.s-footer-bottom {
		padding: 20px 0 30px; }
	.s-footer-copy {
		width: 100%;
		padding-bottom: 20px;
		justify-content: center; }
	.s-footer-bottom-inner {
		justify-content: center; }
	// main page
	//popup call
	.s-popup {
		height: 544px;
		max-width: 300px;
		padding: 30px 20px; }
	.s-popup-title {
		font-size: 26px;
		line-height: 37px;
		text-align: left; }
	.s-popup-descr {
		font-size: 14px;
		line-height: 24px;
		text-align: left;
		padding-top: 25px; }
	.s-popup-form-item p {
		font-size: 10px;
		line-height: 12px; }
	.s-popup-form-item + .s-popup-form-item {
		padding-top: 30px; }
	.s-popup-info {
		font-size: 12px;
		line-height: 19px; }
	.s-popup .mfp-close {
		width: 15.78px;
		height: 15.83px;
		top: 10px;
		right: 10px; }
	.s-popup-form-item input {
		font-size: 12px; }
	//popup call
	// page events
	.p-events-title {
		font-size: 32px;
		line-height: 40px; }
	.p-events-descr {
		font-size: 14px;
		line-height: 24px;
		padding-top: 27px; }
	.p-events-button {
		padding-top: 29px; }
	.p-events-contant {
		padding-top: 60px;
		padding-bottom: 30px; }
	.p-events-block {
		width: 50%; }
	.s-pager-navi li a.prev {
		width: 10px;
		height: 17px;
		background-image: url("../img/arrow-left-m.svg"); }
	.s-pager-navi li a.next {
		width: 10px;
		height: 17px;
		background-image: url("../img/arrow-left-m.svg"); }
	.s-pager-navi li {
		margin-right: 21px;
		&.hide-m {
			display: none; } }
	.p-events {
		padding: 48px 0 85px; }
	//page events
	// page news
	.p-events-contant {
		padding-bottom: 36px; }
	.p-news {
		padding-bottom: 84px; }
	//page news
	// page partners
	.p-partners .s-partners-item-inner {
		width: 100%; }
	.s-partners-count {
		padding-top: 17px; }
	.p-partners-block {
		margin-bottom: 23px; }
	.p-partners-info {
		padding-top: 39px;
		padding-bottom: 50px; }
	.p-partners-info-descr {
		font-size: 14px;
		line-height: 24px;
		a {
			color: #20A68B; } }
	.p-partners-line1 {
		background-image: url("../img/line3-m.svg"); }
	.p-partners-map-contant {
		padding-top: 0px;
		background: #E5E5E5;
		padding-bottom: 268px;
		position: relative; }
	.p-partners-map .s-about-title {
		padding-bottom: 48px; }
	.p-partners-map-map {
		height: 620px; }
	.p-partners-map-block {
		left: 15px;
		right: 15px;
		margin: 0 auto;
		width: calc(100% - 30px);
		bottom: 83px;
		top: auto; }
	.p-partners-map-contant .s-partners-item-inner {
		height: 337px; }
	// page partners
	// page contacts
	.p-contacts-img img {
		width: 80px;
		height: 80px; }
	.p-contacts-img {
		width: 80px;
		padding-top: 5px; }
	.p-contacts-text {
		width: calc(100% - 80px);
		padding-left: 13px;
		padding-top: 0; }
	.p-contacts-subtitle {
		font-size: 11px;
		line-height: 20px; }
	.p-contacts-name {
		font-size: 20px;
		line-height: 37px;
		padding-top: 5px; }
	.p-contacts-descr {
		font-size: 14px;
		line-height: 24px;
		padding-top: 20px; }
	.p-contacts-info {
		padding-top: 35px;
		padding-bottom: 65px; }
	.p-contacts-map-map {
		height: 390px; }
	.p-contacts-map {
		padding-top: 113px; }
	.p-contacts-map-block {
		left: 15px;
		right: 15px;
		width: calc(100% - 30px);
		top: 0;
		padding: 17px 26px 40px; }
	.p-contacts-map-list {
		padding-top: 28px; }
	.p-contacts-map-list p {
		font-size: 12px;
		line-height: 15px;
		&.phone {
			a {
				color: #20A68B; } } }
	.p-contacts-map-list p.mail a {
		font-size: 12px;
		line-height: 15px; }
	.p-contacts-map-list li + li {
		padding-top: 26px; }
	.p-contacts-form-inner {
		.s-popup-title {
			text-align: center; } }
	.p-contacts-form .s-popup-descr {
		text-align: center; }
	.p-contacts-form {
		padding-top: 60px;
		padding-bottom: 88px; }
	.s-popup-form-item + .s-popup-form-item {
		padding-top: 36px; }
	.p-contacts-form .s-popup-button {
		padding-top: 0; }
	.p-contacts-form .b-button {
		max-width: 100%; }
	// page contacts
	//page contant
	.p-contant-home {
		padding: 17px 0;
		min-height: 679px; }
	.s-breadcrumb-list li {
		font-size: 12px;
		line-height: 20px;
		margin-right: 21px;
		margin-bottom: 3px; }
	.s-breadcrumb-list li::after {
		top: 40%;
		right: -14px; }
	.p-contant-article {
		padding: 27px 10px; }
	.p-contant-article p {
		font-size: 14px;
		line-height: 24px; }
	.p-contant-article h3 {
		font-size: 14px;
		line-height: 17px;
		padding-top: 28px;
		padding-bottom: 10px; }
	.p-contant-article ul {
		padding-bottom: 28px; }
	.p-contant-article ul li {
		font-size: 14px;
		line-height: 24px;
		&:before {
			width: 4px;
			height: 4px;
			top: 9px; } }
	.p-contant-slider {
		padding-top: 25px;
		width: 100%;
		max-width: 658px;
		margin: 0 auto;
		overflow: hidden;
		padding-bottom: 25px;
		img {
			width: 100%;
			display: block; } }
	.p-contant-slider-nav {
		display: none; }
	.p-contant-slider .slick-arrow.slick-prev {
		left: -15px;
		&:hover {
			border: 1px solid #fff; }
		&:before {
			background-image: url("../img/arrow-prev.svg"); } }
	.p-contant-slider .slick-arrow.slick-next {
		right: -15px;
		&:hover {
			border: 1px solid #fff; }
		&:before {
			background-image: url("../img/arrow-prev.svg"); } }
	.p-contant .s-events {
		padding-top: 32px; }
	.p-contant-slider-for .slick-dots {
		max-width: 100%; }
	//page contant
	//page article
	.s-breadcrumb.inner {
		padding: 30px 0; }
	.p-article-article {
		padding: 46px 10px;
		overflow: hidden; }
	.p-contant-article blockquote {
		font-size: 18px;
		line-height: 35px;
		padding: 0 41px;
		margin-top: 47px; }
	.p-contant-article blockquote::before {
		left: 0; }
	.p-contant-article blockquote::after {
		right: 0;
		bottom: -17px; }
	.b-author-name {
		font-size: 14px;
		line-height: 17px; }
	.b-author-descr {
		font-size: 14px;
		line-height: 23px; }
	.b-author {
		padding-top: 61px;
		padding-bottom: 50px; }
	.b-author-text {
		padding-left: 14px; }
	.b-quote {
		margin: 0 -15px;
		width: calc(100% + 30px);
		padding: 36px 15px; }
	.b-quote p {
		font-weight: 300;
		font-size: 18px;
		line-height: 35px; }
	.p-contant-article ul li ul {
		padding-left: 18px;
		padding-top: 13px; }
	.p-contant-article ul li ul li::before {
		width: 4px;
		height: 2px;
		top: 12px; }
	.p-contant-article ol li {
		font-size: 14px;
		line-height: 24px; }
	.p-contant-article ol li::before {
		font-size: 14px;
		line-height: 24px;
		left: -2px; }
	.p-contant-article ol li ol li::before {
		content: counter(step-counter) "." counter(step-counter1);
		top: -2px;
		left: -9px; }
	.p-contant-article ol {
		padding-bottom: 43px; }
	.p-contant-article table {
		table-layout: fixed; }
	.p-contant-article table th {
		padding: 0px 15px 7px;
		width: 120px; }
	.p-contant-article table td {
		padding: 22px 15px; }
	.p-contant-table {
		padding-top: 11px;
		width: auto;
		max-width: 100%;
		overflow-y: scroll; }
	.p-article-video-overlay {
		height: 240px; }
	.p-article-video iframe {
		height: 240px; }
	.p-article-video-btn {
		width: 75px;
		height: 75px;
		background-size: cover; }
	//page article
	// popup1
	.s-popup1 {
		max-width: 302px; }
	.s-popup1-text {
		width: 100%;
		padding: 0; }
	.s-popup1-info {
		width: 100%;
		padding: 0;
		padding-top: 30px; }
	.s-popup1-scroll {
		height: auto; }
	.s-popup1-contant {
		padding: 37px 21px 50px 20px; }
	.s-popup1-descr {
		font-size: 14px;
		line-height: 24px; }
	.s-popup1-list p {
		font-size: 12px;
		line-height: 15px;
		strong {
			font-size: 12px;
			line-height: 18px; } }
	.s-popup1-list p + p {
		padding-top: 9px; }
	.s-popup1-button {
		padding-top: 32px;
		.b-button {
			max-width: 217px; } }
	.s-popup1 .slick-arrow.slick-prev {
		left: -27px; }
	.s-popup1 .slick-arrow.slick-prev::before {
		width: 9px;
		height: 22px; }
	.s-popup1 .slick-arrow.slick-next {
		right: -27px; }
	.s-popup1 .slick-arrow.slick-next::before {
		width: 9px;
		height: 22px; }
	.s-popup1-slider {
		margin: 0 -100px;
		width: calc(100% + 200px); }
	.s-popup1 .slick-arrow.slick-prev {
		left: 76px; }
	.s-popup1 .slick-arrow.slick-next {
		right: 76px; }
	.p-contacts-map {
		overflow: hidden;
		height: 503px; }
	.p-contacts-map-map {
		height: 630px; } }

@include respond-to(mobile) {
	// main page
	.s-about-img {
		margin: -74px -161px 0; }
	.s-footer-line {
		background-image: url("../img/line-m.svg"); }
	.s-footer-line1 {
		background-image: url("../img/line-m.svg"); }
	.s-footer-copy {
		justify-content: flex-start; }
	.s-footer-bottom-inner {
		justify-content: flex-start; }
	.s-footer-contacts-block {
		width: 100%;
		text-align: center; }
	.s-footer-contacts-item p {
		text-align: center; }
	.s-footer-work {
		padding-top: 7px;
		text-align: center; }
	// main page
	// page events
	.p-events-block {
		width: 100%; }
	// page events
	// page news
	.p-news-block {
		width: 100%; }
	.p-news .s-news-item-block {
		padding: 29px 21px 37px; }
	.p-news-block {
		margin-bottom: 24px; }

	// page news
	// page partners
	.p-partners-block {
		width: 100%; }
	//page contant
	.p-contant-slider-for .slick-dots li button {
		width: 6px;
		height: 6px; }
	.p-contant-slider-for .slick-dots {
		bottom: -5px;
		max-width: 100%; }
	.p-contant-slider-for .slick-dots li {
		margin: 0 4px; }
	.p-contant-slider .slick-arrow.slick-prev {
		left: -15px;
		width: 56px;
		height: 56px;
		&:hover {
			border: 1px solid #fff; }
		&:before {
			background-image: url("../img/arrow-prev-m.svg");
			width: 8.75px;
			height: 16.36px; } }
	.p-contant-slider .slick-arrow.slick-next {
		right: -15px;
		width: 56px;
		height: 56px;
		&:hover {
			border: 1px solid #fff; }
		&:before {
			background-image: url("../img/arrow-prev-m.svg");
			width: 8.75px;
			height: 16.36px; } }
	.p-contant-date {
		padding-top: 34px;
		font-size: 14px;
		line-height: 17px; }
	.p-contant .p-events-title {
		padding-top: 16px; }
	.p-contant-descr {
		font-size: 14px;
		line-height: 24px;
		padding-top: 30px; } }

@include respond-to(mobile1);


@include respond-to(mobile2);


